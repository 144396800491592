import { toast } from 'react-toastify';
import { getToken } from './auth';

const apiURL = `${(process.env.REACT_APP_API_URL || "")}/rpc`;

export async function newActionPlan(url, path, username, password, privateKey, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.NewActionPlan",
      params: [url, path, username, password, privateKey, getToken()]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "newActionPlan"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function listActionPlans(success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.ListActionPlans",
      params: [getToken()]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "listActionPlans"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function updateActionPlan(name, username, password, privateKey, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.UpdateActionPlan",
      params: [name, username, password, privateKey, getToken()]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "updateActionPlan"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function deleteActionPlan(name, success, error) {
  const response = await fetch(apiURL, 
    {
      method: "POST",
      body: JSON.stringify({
        id: 1,
        method: "v1.DeleteActionPlan",
        params: [name, getToken()]
      })
    });
    const data = await response.json();
    if (data.error) {
      toast.error(data.error.message, {toastId: "deleteActionPlan"});
      error && error(data.error.message);
    } else {
      success && success(data.result);
    }
}

export async function setExcludedActions(name, exclusions, success, error) {
  console.log(name, exclusions);
  const response = await fetch(apiURL, 
    {
      method: "POST",
      body: JSON.stringify({
        id: 1,
        method: "v1.SetExcludedActions",
        params: [name, exclusions, getToken()]
      })
    });
    const data = await response.json();
    console.log(data);
    if (data.error) {
      toast.error(data.error.message, {toastId: "setExcludedActions"});
      error && error(data.error.message);
    } else {
      success && success(data.result);
    }
}

export async function saveStackContext(name, ageSecretKey, context, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.SaveStackContext",
      params: [name, ageSecretKey, context, getToken()]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "saveStackContext"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function listStackContexts(ageSecretKey, decrypt, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.ListStackContexts",
      params: [ageSecretKey, decrypt, getToken()]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "listStackContexts"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function newUser(email, password, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.NewUser",
      params: [email, password, ""]
    })
  });
  const data = await response.json();
  console.log(data);
  if (data.error) {
    toast.error(data.error.message, {toastId: "newUser"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function newSessionToken(email, password, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.NewSessionToken",
      params: [email, password]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "newSessionToken"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function refreshSessionToken(token, success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.RefreshSessionToken",
      params: [token]
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "refreshSessionToken"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function createEarlyAccessPaymentIntent(success, error) {
  const response = await fetch(apiURL, 
  {
    method: "POST",
    body: JSON.stringify({
      id: 1,
      method: "v1.CreateEarlyAccessPaymentIntent",
    })
  });
  const data = await response.json();
  if (data.error) {
    toast.error(data.error.message, {toastId: "createEarlyAccessPaymentIntent"});
    error && error(data.error.message);
  } else {
    success && success(data.result);
  }
}

export async function getGithubAccessToken(code, success, error) {
  const response = await fetch(apiURL, 
    {
      method: "POST",
      body: JSON.stringify({
        id: 1,
        method: "v1.GetGithubAccessToken",
        params: [code]
      })
    });
    const data = await response.json();
    if (data.error) {
      toast.error(data.error.message, {toastId: "getGithubAccessToken"});
      error && error(data.error.message);
    } else {
      success && success(data.result);
    }
}