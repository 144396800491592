import { useState } from "react";
import LoginFormSignIn from "./LoginFormSignIn";
import LoginFormRegister from "./LoginFormRegister";
import logo from './logo-dark.svg';
import "./LoginForm.css";

function LoginForm() {
  const [option, setOption] = useState("signIn");

  const handleSetOption = opt => setOption(opt);
  const isSelectedOption = opt => opt === option; 

  const renderOptionForm = () => {
    return (option === "signIn") ? <LoginFormSignIn /> : <LoginFormRegister />
  }

  return (
    <div className="LoginForm">
      <div className="Brand">
        <img src={logo} alt="logo"/>
      </div>
      <div className="Options">
        <button 
          onClick={() => handleSetOption("signIn")}
          data-selected={isSelectedOption("signIn")}
          className="LoginOption"
        >
          Login
        </button>
        <button 
          onClick={() => handleSetOption("register")}
          data-selected={isSelectedOption("register")}
          className="RegisterOption"
        >
          Register
        </button>
      </div>
      {renderOptionForm()}
    </div>
  )
}

export default LoginForm;