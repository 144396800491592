import MoonLoader from "react-spinners/MoonLoader";
import './ResourcesLoadingOverlay.css';

function ResourcesLoadingOverlay() {
  return (
    <div className="ResourcesLoadingOverlay">
      <MoonLoader
        color="#897DBB"
        loading={true}
        cssOverride={{display: "block",margin: "0 auto"}}
        size={50}
        aria-label="Loading Spinner"
      />
    </div>
  )
}

export default ResourcesLoadingOverlay;