import { useOutletContext } from "react-router-dom";
import ActionPlansListItem from './ActionPlansListItem';
import ResourcesLoadingOverlay from "./ResourcesLoadingOverlay";
import Tips from "./Tips";
import './ActionPlansList.css';

function ActionPlansList() {
  const [actionPlans, loadingResources, reloadActions] = useOutletContext();

  return (
    <div className="ActionPlansList Container">
      {(!loadingResources && actionPlans.length === 0) 
        ? 
          <div className="NoActionPlans">
            <h1 >You have no action plans</h1>
            <small>TIP: Click "New Action Plan" to create one</small> 
          </div>
        : 
          null
      }
      {(actionPlans.length > 0)
        ?
          <>
            <h1 className="ActionPlansListHeading">Action Plans</h1>
            <Tips tips={[
              "Rerun an action plan by clicking the sync button next to the action plan name",
              "Syncing an action plan will reset previously excluded actions",
              "Click on an action item to view a description",
              "Actions can toggled on and off by clicking the checkbox next to the action name",
            ]}/>
          </>
        :
          null
      }
      {actionPlans.map((plan, index) => (
        <ActionPlansListItem 
          key={index} 
          name={plan.name} 
          actions={plan.actions} 
          excludedActions={plan.exclusions}
          repository={plan.repository}
          basicAuth={plan.basicAuth}
          reloadActions={reloadActions} 
        />
      ))}
      {(loadingResources) ? <ResourcesLoadingOverlay/> : null}
    </div>
  )
}

export default ActionPlansList