export function generateDot(root) {
  const ele = document.getElementsByClassName(root)[0]
  this.obj = document.createElement("div");
  this.obj.classList.add("DotBG");
  this.obj.style.top = ((ele.scrollHeight - 20) * Math.random()) + 'px';
  this.obj.style.left = ((ele.scrollWidth - 20) * Math.random()) + 'px';
  window.addEventListener("resize", () => {
    this.obj.style.top = ((ele.scrollHeight - 20) * Math.random()) + 'px';
    this.obj.style.left = ((ele.scrollWidth - 20) * Math.random()) + 'px';
  });
  this.size = Math.floor(10 * Math.random()) + 10;
  this.obj.style.height = this.size + 'px';
  this.obj.style.backgroundColor = (Math.random() > .5) ? "#897DBB" : "#76CABC";
  this.obj.style.width = this.size + 'px';
  let opacity = Math.random();
  let maxOpacity = .5;
  this.obj.style.opacity = (opacity < maxOpacity) ? opacity : maxOpacity;
  ele.appendChild(this.obj);
}