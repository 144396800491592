import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { newUser } from "./utils/api";
import { signIn } from "./utils/auth";
import "./LoginFormRegister.css";

function LoginFormRegister() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  
  const handleSetEmail = e => setEmail(e.target.value);
  const handleSetPassword = e => setPassword(e.target.value);
  const handleSetConfirmPassword = e => setConfirmPassword(e.target.value);

  const handleRegisterUser = () => {
    if (email.length > 0 && password.length > 0 && confirmPassword.length > 0) {
      newUser(email, password, () => {
         signIn(email, password, navigate);
      });
    } 
  }

  return (
    <div className="LoginFormRegister">
      <input type="text" onChange={handleSetEmail} value={email} placeholder="Email" />
      <input type="password" onChange={handleSetPassword} value={password} placeholder="Password" />
      <input type="password" onChange={handleSetConfirmPassword} value={confirmPassword} placeholder="Confirm Password" />
      <button onClick={handleRegisterUser}>Register</button>
    </div>
  )  
}

export default LoginFormRegister;