import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { listActionPlans, listStackContexts, saveStackContext } from "../utils/api";
import './Stacks.css';
import StacksList from '../StacksList';

function StackOptions({
  stackContexts, 
  setStackContext, 
  actionPlans,
  setOverrides, 
  saveButtonEnabled, 
  saveInputs,
  ageSecretKey,
  setAgeSecretKey,
  decryptInputs,
  toggleInputs
}) {
  const [newContext, setNewContext] = useState("");

  return (
    <>
      <input type="password" onChange={(e) => setAgeSecretKey(e.target.value)} className="StackAgeSecretKeyContextOption" value={ageSecretKey} placeholder="Age Secret Key" />
      <button 
        onClick={() => {
          if (!decryptInputs && ageSecretKey === "") {
            return;
          }
          toggleInputs(!decryptInputs)}
        } 
        data-enabled={decryptInputs} className="StackAgeSecretKeyContextOptionSubmit"
      >
        {(decryptInputs)
          ? <svg viewBox="0 0 24 24"><path d="m16 9.5v-3.5a4 4 0 0 0 -8 0 1 1 0 0 0 2 0 2 2 0 0 1 4 0v3.5h-6a2.5 2.5 0 0 0 -2.5 2.5v7a2.5 2.5 0 0 0 2.5 2.5h8a2.5 2.5 0 0 0 2.5-2.5v-7a2.5 2.5 0 0 0 -2.5-2.5zm-3 7.22v.28a1 1 0 0 1 -2 0v-.28a2 2 0 0 1 -1-1.72 2 2 0 0 1 4 0 2 2 0 0 1 -1 1.72z" /></svg>
          : <svg viewBox="0 0 24 24"><path d="m16 9.5v-3.5a4 4 0 0 0 -8 0v3.5a2.5 2.5 0 0 0 -2.5 2.5v7a2.5 2.5 0 0 0 2.5 2.5h8a2.5 2.5 0 0 0 2.5-2.5v-7a2.5 2.5 0 0 0 -2.5-2.5zm-3 7.22v.28a1 1 0 0 1 -2 0v-.28a2 2 0 0 1 -1-1.72 2 2 0 0 1 4 0 2 2 0 0 1 -1 1.72zm1-7.22h-4v-3.5a2 2 0 0 1 4 0z"/></svg>
        }
      </button>
      <select onChange={(e) => {setStackContext(e.target.value)}} className="StackContextSelectContextOption">
        {stackContexts.map((context, index) => <option key={index}>{context}</option>)}
      </select>
      <select onChange={(e) => {setOverrides(e.target.value)}}  className="StackContextSelectOverridesOption">
        <option disabled>select plan</option>
        <option value="none">none</option>
        {Object.values(actionPlans).map((app, index) => <option key={index}>{app.name}</option>)}
      </select>
      {/* <input onChange={(e) => setNewContext(e.target.value)} className="StackNewContextInputContextOption" value={newContext} placeholder="Save As" /> */}
      <button onClick={saveInputs} disabled={!saveButtonEnabled} data-enabled={saveButtonEnabled && decryptInputs} className="StackSaveButtonContextOption">Save Inputs</button>
    </>
  )
}

function Stacks() {
  const [setOptions, SetDisableNavigation] = useOutletContext();
  const [actionPlans, setActionPlans] = useState([]);
  const [stackInputs, setStackInputs] = useState({default: {}});
  const [stackContext, setStackContext] = useState("default");
  const [inputChanged, setInputChanged] = useState(false);
  const [overrides, setOverrides] = useState("none");
  const [inputs, setInputs] = useState({});
  const [loadingResources, setLoadingResources] = useState(true);
  const [loadingActionPlans, setLoadingActionPlans] = useState(true);
  const [loadingStackInputs, setLoadingStackInputs] = useState(true);
  const [ageSecretKey, setAgeSecretKey] = useState("");
  const [decryptInputs, setDecryptInputs] = useState(false);

  let navigate = useNavigate();

  const checkResourcesLoaded = useCallback(() => {
    if (!loadingActionPlans && !loadingStackInputs) {
      setLoadingResources(false);
    }  
  }, [loadingActionPlans, loadingStackInputs]);

  const updateInput = (name, value) => {
    inputs[name] = value
    setInputs(inputs);
  };

  useEffect(() => {
    checkResourcesLoaded();
  }, [loadingActionPlans, loadingStackInputs, checkResourcesLoaded])

  useEffect(() => {
    listActionPlans((data) => {
      if (data.length === 0) {
        navigate("/");
      }
      SetDisableNavigation(false);
      setActionPlans(data);
      setLoadingActionPlans(false);
      checkResourcesLoaded();
    }, () => {
      setLoadingActionPlans(false);
      checkResourcesLoaded();
    });
  }, [checkResourcesLoaded, setLoadingActionPlans]);

  useEffect(() => {
    listStackContexts(ageSecretKey, decryptInputs, (data) => {
      if (Object.keys(data).length < 1) {
        setStackInputs({"default": {}, });
      } else {
        setStackInputs(data);
      }
      setLoadingStackInputs(false);
      checkResourcesLoaded();
    }, () => {
      setLoadingStackInputs(false);
      checkResourcesLoaded();
    });
  }, [ageSecretKey, decryptInputs, checkResourcesLoaded]);

  useEffect(() => {
    const changeStackContext = (context) => {
      setInputs({});
      setStackContext(context);
      setInputChanged(false);
    };
  
    const changeOverrides = (overrides) => {
      setInputs({});
      setOverrides(overrides);
      setInputChanged(false);
    };

    const toggleInputs = (decrypt) => {
      setLoadingResources(true);
      listStackContexts(ageSecretKey, decrypt, (data) => {
        setStackInputs(data);
        setDecryptInputs(decrypt);
        setLoadingStackInputs(false);
        checkResourcesLoaded();
      }, () => {
        setLoadingStackInputs(false);
        setDecryptInputs(false);
        setAgeSecretKey("");
        checkResourcesLoaded();
      });
    }
  
    const saveInputs = () => {
      let context = stackInputs[stackContext];
      if (overrides !== "none") {
        if (!context["_overrides"]) {
          context["_overrides"] = {};
        }
        if (context["_overrides"][overrides] === undefined) {
          context["_overrides"][overrides] = {};
        };
        Object.entries(inputs).forEach((entry) => {
          if (entry[1] !== "") {
            context["_overrides"][overrides][entry[0]] = entry[1];
          } else {
            delete context["_overrides"][overrides][entry[0]];
            if (Object.values(context["_overrides"][overrides]).length < 1) {
              delete context["_overrides"][overrides];
              if (Object.values(context["_overrides"]).length < 1) {
                delete  context["_overrides"];
              }
            }
          }
        });
      } else {
        Object.entries(inputs).forEach((entry) => {
          if (entry[1] !== "") {
            context[entry[0]] = entry[1];
          } else {
            delete context[entry[0]];
          }
        });
      }
      if (!loadingStackInputs) {
        setLoadingResources(true);
      }
      saveStackContext(stackContext, ageSecretKey, context, () => {
        setInputChanged(false);
        checkResourcesLoaded();
      });
    };

    setOptions(
      <StackOptions
        stackContext={stackContext}
        stackContexts={Object.keys(stackInputs)}
        setStackContext={changeStackContext}
        setOverrides={changeOverrides}
        saveButtonEnabled={inputChanged}
        saveInputs={saveInputs}
        actionPlans={actionPlans}
        ageSecretKey={ageSecretKey}
        setAgeSecretKey={setAgeSecretKey}
        toggleInputs={toggleInputs}
        decryptInputs={decryptInputs}
      />
    );
  }, [
    actionPlans, 
    inputChanged, 
    ageSecretKey, 
    decryptInputs,
    stackContext,
    stackInputs,
    inputs,
    overrides,
    loadingStackInputs,
    setOptions,
    checkResourcesLoaded,
  ]);

  return (
    <div className="Stacks">
      <StacksList
        context={stackContext}
        overrides={overrides}
        stackInputs={stackInputs[stackContext]}
        actionPlans={actionPlans}
        setInputChanged={setInputChanged}
        updateInput={updateInput}
        loadingResources={loadingResources}
        inputsDisabled={!decryptInputs}
      />
    </div>
  );
}

export default Stacks;