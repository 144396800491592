import {useState} from "react";
import "./Tips.css";

function Tips({tips}) {
    const [tipIndex, setTipIndex] = useState(0);
  
    return (
      <div className="Tips">
        <p className="TipText">
          <svg className="TipIcon" viewBox="0 0 48 48">
              <path d="m17.1 34.2c.1.8.2 1.5.2 2.3h13.4c0-.8.1-1.6.2-2.3z"/>
              <path d="m17.3 38.5v3.5c0 .6.4 1 1 1h.3c.5 2.5 2.8 4.5 5.4 4.5 2.7 0 4.9-1.9 5.4-4.5h.3c.6 0 1-.4 1-1v-3.5z"/>
              <path d="m23.2.5c-7.1.4-13 6-13.8 13.1-.4 4 .8 7.9 3.3 10.9 1.9 2.3 3.3 4.9 4 7.6h14.6c.7-2.7 2.1-5.4 4-7.7 2.2-2.6 3.4-5.9 3.4-9.3 0-8.3-7.1-15-15.5-14.6z"/>
          </svg>
  
          TIP: {tips[tipIndex]}
        </p>
        {tips.map((_, index) => <span onClick={() => setTipIndex(index)} data-selected={index === tipIndex} key={index} className="TipDot"></span>)}
      </div>
    )
}

  export default Tips