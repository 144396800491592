import {useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { listActionPlans } from "../utils/api";
import ActionPlanContextOptions from '../ActionPlansContextOptions';
import './ActionPlans.css';

function ActionPlans() {
  const [setOptions, SetDisableNavigation] = useOutletContext();
  const [actionPlans, setActionPlans] = useState([]);
  const [loadingResources, setLoadingResource] = useState(true);
  
  const reloadActions = (callback) => {
    setLoadingResource(true);
    listActionPlans((data) => {
      setActionPlans(data);
      setLoadingResource(false);
      callback && callback();
    });
  }

  useEffect(() => {
    listActionPlans((data) => {
      setActionPlans(data);
      setLoadingResource(false);
    });
  }, []);

  useEffect(() => {
    if (!loadingResources && actionPlans.length === 0) {
      SetDisableNavigation(true);
    } else {
      SetDisableNavigation(false);
    }
    setOptions(<ActionPlanContextOptions/>);
  }, [setOptions, actionPlans, loadingResources, SetDisableNavigation]);

  return (
    <div className="ActionPlans">
      <Outlet context={[actionPlans, loadingResources, reloadActions]} />
    </div>
  );
}

export default ActionPlans;