import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "./utils/auth";
import "./LoginFormSignIn.css";

function LoginFormSignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSetEmail = e => setEmail(e.target.value);
  const handleSetPassword = e => setPassword(e.target.value);

  const handlerSignInUser = () => {
    if (email.length > 0 && password.length > 0) {
      signIn(email, password, navigate);
    } 
  }

  return (
    <div className="LoginFormSignIn">
      <input type="text" onChange={handleSetEmail} value={email} placeholder="Email" />
      <input type="password" onChange={handleSetPassword} value={password} placeholder="Password" />
      <button onClick={handlerSignInUser}>Login</button>
    </div>
  )  
}

export default LoginFormSignIn;