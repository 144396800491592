import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ActionPlans from './routes/ActionPlans';
import Root from './routes/Root';
import Login from "./routes/Login";
import Stacks from './routes/Stacks';
import Upgrade from './routes/Upgrade';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import NewApplication from './NewApplication';
import ActionPlansList from './ActionPlansList';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <ActionPlans />,
        children: [
          {
            path: "",
            element: <ActionPlansList />
          },
          {
            path: "new",
            element: <NewApplication />
          }
        ]
      },
      {
        path: "stacks",
        element: <Stacks />
      },
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/upgrade",
    element: <Upgrade />
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
