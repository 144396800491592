import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import './Option.css';

function Option({options, disableNavigation}) {
    let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("");
    
    const setSelectedItemFromPathname = () => {
      if (window.location.pathname.indexOf("/stacks") >= 0) {
        setSelectedItem("stacks");
      } else if (window.location.pathname.indexOf("/new") >= 0) {
        setSelectedItem("action-plans");
      }
    }

    const selectItem = (item) => {
      setSelectedItem(item); 
      navigate(`/${item}`);
    }

    const isSelectedItem = (item) => {
      return item === selectedItem
    }

    useEffect(setSelectedItemFromPathname, [selectedItem]);

    return (
      <div className="Container">
        <div className="Option">
          {(!disableNavigation) ? (
            <div className="Links">
              <svg 
                className="OptionLink OptionApplicationsIcon" 
                onClick={() => selectItem("")} 
                data-selected={isSelectedItem("")}
                viewBox="0 0 64 64" 
              >
                <path d="m29 11v14a4 4 0 0 1 -4 4h-14a4 4 0 0 1 -4-4v-14a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4zm24-4h-14a4 4 0 0 0 -4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4v-14a4 4 0 0 0 -4-4zm-28 28h-14a4 4 0 0 0 -4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4v-14a4 4 0 0 0 -4-4zm21 0a11 11 0 1 0 11 11 11 11 0 0 0 -11-11z"/>
              </svg>
              <svg 
                className="OptionLink OptionStacksIcon" 
                onClick={() => selectItem("stacks")}
                data-selected={isSelectedItem("stacks")} 
                viewBox="0 0 32 32"
              >
                <polygon points="0,22 16,26 32,22 32,26 16,30 0,26"/>
                <polygon points="0,14 16,18 32,14 32,18 16,22 0,18"/>
                <polygon points="0,6 16,2 32,6 32,10 16,14 0,10"/>
              </svg>
            </div>
          ) : null}
          <div className="ContextOptions">{options}</div>
        </div>
      </div>
    );
  }
  
  export default Option;
   