import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { authTokenValid } from "../utils/auth";
import { generateDot } from "../utils/dots";
import { useNavigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import 'react-toastify/dist/ReactToastify.css';
import "./Login.css";

function Login() {
    let navigate = useNavigate();

    useEffect(() => {
      for(let i = 0 ; i < 25; i++ ){
        new generateDot("Login");
      }
    });

    useEffect(() => {
      if (authTokenValid() === true) {
        navigate("/");
      }
    }, [navigate]);

    return (
      <div className="Login">
        <LoginForm />
        <ToastContainer autoClose={5000} position="bottom-center" theme="dark" />
      </div>
    );
}

export default Login;