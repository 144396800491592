import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import MoonLoader from "react-spinners/MoonLoader";
import ResourcesLoadingOverlay from "./ResourcesLoadingOverlay";
import { newActionPlan } from './utils/api';
import './NewApplication.css';

function ApplicationGeneratingLoader() {
  return (
    <div className="ApplicationGeneratingLoader">
      <div className="ApplicationGeneratingLoaderStatus">
        <MoonLoader
          color="#897DBB"
          loading={true}
          cssOverride={{display: "block",margin: "0 auto"}}
          size={50}
          aria-label="Loading Spinner"
        />
        <p>Generating Action Plan</p>
      </div>
      <div className="ApplicationGeneratingLoaderOverlay"></div>
    </div>
  );
}

function NewApplicationPrompt({showSSHKeyPrompt, repositoryPrompt, publicKeyPrompt}) {
  return (
    <>
      <div className="Prompt">
        <div className="Heading">
          <h1>Enter a git repository to get started.</h1>
          <small>TIP: Using ssl? You will prompted for your key next</small>
        </div>
        {(!showSSHKeyPrompt) ? repositoryPrompt : publicKeyPrompt}
      </div>
    </>
  )
}

function NewApplication() {
    const [gitRepository, setGitRepository] = useState("");
    const [gitRepositoryPath, setGitRepositoryPath] = useState("");
    const [gitRepositoryUsername, setGitRepositoryUsername] = useState("");
    const [gitRepositoryPassword, setGitRepositoryPassword] = useState("");
    const [sshKey, setSSHKey] = useState("");
    const [showSSHKeyPrompt, setShowSSHKeyPrompt] = useState(false); 
    const [generatingActionPlan, setGeneratingActionPlan] = useState(false);
    const outletContext = useOutletContext();
    const reloadActions = outletContext[2];
    const loadingResources = outletContext[1];

    let navigate = useNavigate();

    const generateActionPlan = () => {
      if (sshKey === "" && gitRepository.indexOf("git@") === 0) {
        setShowSSHKeyPrompt(true);
      } else if (gitRepository !== "") {
        setGeneratingActionPlan(true);
        newActionPlan(gitRepository, gitRepositoryPath, gitRepositoryUsername, gitRepositoryPassword, sshKey, () => {
          setGeneratingActionPlan(false);
          reloadActions();
          navigate("/");
        }, () => {
          setGeneratingActionPlan(false);
        });
      }
    }

    const repositoryPrompt = (
      <div className="RepositoryPrompt">
        <div className="PromptInput">
          <svg viewBox="0 0 24 25">
            <path d="m13.414 3.83227c-.3751-.37492-.8837-.58554-1.414-.58554s-1.0389.21062-1.414.58554l-.672.67197 1.568 1.56793c.3391-.09037.6961-.09 1.035.00106s.648.26962.8961.51777c.2482.24816.4268.55719.5178.89611.0911.33892.0915.69583.0011 1.03494l2.05 2.04985c.4253-.1139.8763-.0844 1.2831.084.4067.1685.7466.4665.9667.8477.2202.3813.3084.8246.2509 1.2611s-.2573.8418-.5687 1.1531c-.3113.3113-.7167.5112-1.1532.5687-.4365.0574-.8798-.0308-1.2611-.2509-.3813-.2202-.6792-.56-.8477-.9667-.1685-.4068-.198-.8578-.084-1.283l-1.568-1.5679v4.8537c.3813.2202.6792.5599.8477.9667.1685.4067.198.8576.0841 1.2829-.114.4252-.3651.8009-.7143 1.0689-.3493.268-.7772.4133-1.2175.4133-.4402 0-.8682-.1453-1.2175-.4133-.3492-.268-.6003-.6437-.7143-1.0689-.1139-.4253-.08435-.8762.0841-1.2829.1685-.4068.4664-.7465.8477-.9667v-5.53571c-.3814-.22012-.6794-.55994-.8479-.96674-.16856-.4068-.19811-.85785-.0841-1.28315l-1.568-1.56793-4.672 4.67173c-.37494.3751-.58557.8837-.58557 1.414s.21063 1.0389.58557 1.4139l6.758 6.7577c.3751.3749.8837.5855 1.414.5855s1.0389-.2106 1.414-.5855l6.758-6.7577c.3749-.375.5856-.8836.5856-1.4139s-.2107-1.0389-.5856-1.414zm-4.242-1.41393c.75012-.74985 1.7673-1.17109 2.828-1.17109s2.0779.42124 2.828 1.17109l6.758 6.75768c.7499.75007 1.1711 1.76728 1.1711 2.82788s-.4212 2.0778-1.1711 2.8278l-6.758 6.7577c-.7501.7499-1.7673 1.1711-2.828 1.1711s-2.07788-.4212-2.828-1.1711l-6.758-6.7577c-.74988-.75-1.17114-1.7672-1.17114-2.8278s.42126-2.07781 1.17114-2.82788z"/>
          </svg>
          <input 
            autoFocus 
            onChange={(e) => setGitRepository(e.target.value)} 
            value={gitRepository} 
            placeholder="HTTP or SSH Repository URL" 
            type="text" 
          />
        </div>
        <div className="PromptInput">
          <svg viewBox="0 0 48 48">
            <path d="m47.6205 18.5394a2.9866 2.9866 0 0 0 -2.622-1.5394h-28.4262a4.4293 4.4293 0 0 0 -4.2523 3.1891l-5.6265 19.2909a4.889 4.889 0 0 1 -4.6935 3.52h35.7478a4.89 4.89 0 0 0 4.6944-3.5208l5.4014-18.5183a3.1406 3.1406 0 0 0 -.2231-2.4215z"/>
            <path d="m4.77 38.92 5.63-19.29a6.45 6.45 0 0 1 6.17-4.63h25.43v-1a4 4 0 0 0 -4-4h-15.2443a2.0053 2.0053 0 0 1 -1.5557-.74l-1.9657-2.4171a4.9941 4.9941 0 0 0 -3.8743-1.8429h-11.36a4 4 0 0 0 -4 4v30a2 2 0 0 0 2 2 2.895 2.895 0 0 0 2.77-2.08z"/>
          </svg>
          <input 
            autoFocus 
            onChange={(e) => setGitRepositoryPath(e.target.value)} 
            value={gitRepositoryPath} 
            placeholder="Sub Path (leave blank if using source root)" 
            type="text" 
          />
        </div>
        <div className="PromptInput">
          <svg viewBox="0 0 512 512">
            <path d="M256,0c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,0,256,0z"/>
            <path d="M423.966,358.195C387.006,320.667,338.009,300,286,300h-60c-52.008,0-101.006,20.667-137.966,58.195
              C51.255,395.539,31,444.833,31,497c0,8.284,6.716,15,15,15h420c8.284,0,15-6.716,15-15
              C481,444.833,460.745,395.539,423.966,358.195z"/>
          </svg>
          <input 
            autoFocus 
            onChange={(e) => setGitRepositoryUsername(e.target.value)} 
            value={gitRepositoryUsername} 
            placeholder="Username (optional)" 
            type="text" 
          />
        </div>
        <div className="PromptInput">
          <svg viewBox="0 0 24 24">
            <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
          </svg>
          <input 
            autoFocus 
            onChange={(e) => setGitRepositoryPassword(e.target.value)} 
            value={gitRepositoryPassword} 
            placeholder="Password (optional)" 
            type="password" 
          />
        </div>
        <button className="PromptButton" onClick={generateActionPlan}>Generate Action Plan</button>
      </div>
    )
  
    const publicKeyPrompt = (
      <div className="PublicKeyPrompt">
        <span onClick={() => setShowSSHKeyPrompt(false)} className="GoBack">&larr; go back</span>
        <svg viewBox="0 0 97 97">
          <path d="m9.30273 55.9749c0 5.8542 4.74577 10.6 10.59997 10.6h46.211c1.1046 0 2-.8954 2-2v-2.4417c0-.8149-.4996-1.5388-1.2293-1.9016-5.796-2.8816-9.7789-8.8602-9.7789-15.7747 0-9.7245 7.8832-17.6078 17.6077-17.6078 2.8886 0 5.6148.6956 8.0201 1.9284.8604.441 1.9596-.1467 1.9596-1.1135v-3.4268c0-5.8542-4.7458-10.6-10.6-10.6h-54.1902c-5.8542 0-10.59997 4.7458-10.59997 10.6zm15.29697-27.5443c1.0154-1.0154 2.6616-1.0154 3.677 0l9.8369 9.8369c1.0153 1.0153 1.0153 2.6616 0 3.6769l-9.8369 9.8369c-1.0154 1.0154-2.6616 1.0154-3.677 0-1.0154-1.0153-1.0154-2.6615 0-3.6769l7.9984-7.9984-7.9984-7.9985c-1.0154-1.0153-1.0154-2.6616 0-3.6769zm17.7269 18.9127c-1.436 0-2.6 1.164-2.6 2.6 0 1.4359 1.164 2.6 2.6 2.6h8.3022c1.436 0 2.6-1.1641 2.6-2.6 0-1.436-1.164-2.6-2.6-2.6z"/><path d="m61.1055 44.4569c0-7.5154 6.0924-13.6078 13.6077-13.6078 7.5154 0 13.6078 6.0924 13.6078 13.6078 0 6.626-4.7358 12.1459-11.0073 13.3596v16.4379h6.8978c1.436 0 2.6 1.1641 2.6 2.6s-1.164 2.6-2.6 2.6h-6.8978v6.8623c0 1.436-1.164 2.6-2.6 2.6-1.4359 0-2.6-1.164-2.6-2.6v-9.4302l-.0002-.0321.0002-.0321v-19.0057c-6.2719-1.2132-11.0082-6.7333-11.0082-13.3597zm13.6077-8.4078c-4.6434 0-8.4077 3.7643-8.4077 8.4078s3.7643 8.4078 8.4077 8.4078c4.6435 0 8.4078-3.7643 8.4078-8.4078s-3.7643-8.4078-8.4078-8.4078z"/>
        </svg>
        <textarea 
          autoFocus 
          onChange={(e) => setSSHKey(e.target.value)} 
          value={sshKey} 
          placeholder="Enter private key" 
          type="textarea" 
        />
        <button className="PromptButton" onClick={(e) => {setShowSSHKeyPrompt(false)}}>Enter SSH Key</button>
      </div>
    )

    const renderState = () => (generatingActionPlan) 
      ? <ApplicationGeneratingLoader /> 
      : <NewApplicationPrompt showSSHKeyPrompt={showSSHKeyPrompt} repositoryPrompt={repositoryPrompt} publicKeyPrompt={publicKeyPrompt} />;

    return (
      <>
        {(loadingResources)
          ?  <ResourcesLoadingOverlay/>
          : <div className="NewApplication">{renderState()}</div>
        }
      </>
    )
}

export default NewApplication;