import { useNavigate } from "react-router-dom";
import "./ActionPlansContextOptions.css"

function ActionPlansContextOptions() {
    let navigate = useNavigate();
    return (
        <button 
            onClick={() => navigate("/new")} 
            className="ActionPlansContextOptionNewApplication"
        >
            New Action Plan
        </button>
    )
}

export default ActionPlansContextOptions;