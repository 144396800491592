import {useEffect, useState} from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { authTokenValid, getTrialExpiration, refreshTokenInterval } from "../utils/auth";
import { buyEarlyAccess } from "../utils/stripe";
import { paymentLink } from "../utils/stripe";
import Header from '../Header';
import Option from '../Option';
import 'react-toastify/dist/ReactToastify.css';
import './Root.css';

function Root() {
  const [options, setOptions] = useState("");
  const [disableNavigation, SetDisableNavigation] = useState(true);
  const [authValid, setAuthValid] = useState();

  let navigate = useNavigate();
  
  const trialExpirationDays = () => {
    const exp = getTrialExpiration();
    if (exp === undefined) {
      return null
    }
    const now = new Date(Date.now());
    const expiration = new Date(getTrialExpiration()*1000);
    const difference = expiration.getTime() - now.getTime();
    return Math.ceil(difference / (1000*3600*24));
  }

  const trialExpiration = trialExpirationDays();

  useEffect(() => {
    if (!authTokenValid(navigate)) {
      navigate("/login");
    }
    setAuthValid(true);
    if (trialExpiration !== null && trialExpiration <= 0) {
      navigate("/upgrade");
    } else {
      return () => refreshTokenInterval();
    }
  }, [navigate]);

  return (
    <>
      {authValid &&
          <div className="Root">
            {trialExpiration &&
              <div className="TrialPeriod">
                <span className="TrialPeriodDays">{trialExpiration}</span> days remaining
                <button onClick={buyEarlyAccess} className="UpgradeNow">Upgrade Now</button>
              </div>
            }
            <Header/>
            <Option disableNavigation={disableNavigation} options={options} />
            <div className="ContentArea"><Outlet context={[setOptions, SetDisableNavigation]} /></div>
            <ToastContainer autoClose={5000} position="bottom-center" theme="dark" />
          </div>
      }
      </>
  );
}

export default Root;
