import { useState } from 'react';
import { updateActionPlan, deleteActionPlan } from "./utils/api";
import ActionPlansActionList from './ActionPlansActionsList';
import MoonLoader from "react-spinners/MoonLoader";
import './ActionPlansListItem.css';

function ActionPlansListItem({name, actions, excludedActions, repository, basicAuth, reloadActions}) {
    const [exclusions, setExclusions] = useState(excludedActions);
    const [showActionPlan, setShowActionPlan] = useState(false);
    const [showPublicKeyPrompt, setShowPublicKeyPrompt] = useState(false);
    const [showBasicAuthPrompt, setShowBasicAuthPrompt] = useState(false);
    const [syncingActionPlan, setSyncingActionPlan] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [sshKey, setSSHKey] = useState("");

    const onListItemClick = (e) => {
      if (e.target.className === "ListItemBody" || e.target.className === "ListItemName" || e.target.className === "UnresolvedInputs") {
        setShowActionPlan(!showActionPlan)
      }
    }
  
    const syncActionPlan = () => {
      if (repository.indexOf("git@") !== -1 && sshKey === "") {
        setShowPublicKeyPrompt(true);
      } else if (basicAuth && username === "" && password === "") {
        setShowBasicAuthPrompt(true);
      } else {
        setSyncingActionPlan(true);
        updateActionPlan(name, username, password, sshKey, () => {
          setSyncingActionPlan(false);
          reloadActions(() => {
            setExclusions([]);
          });
        }, () => {
          setSyncingActionPlan(false);
        });
      }
    }

    const removeActionPlan = () => {
      deleteActionPlan(name, () => {
        reloadActions();
      })
    }

    const listItem = (
      <div onClick={onListItemClick} className="ActionPlansListItem">
        <div className="ListItemBody">
          <button onClick={syncActionPlan} className="SyncButton">
            <svg viewBox="0 0 512 512">
              <path d="m512 86.401v91.199c0 17.673-14.327 32-32 32h-91.199c-17.673 0-32-14.327-32-32s14.327-32 32-32h24.45c-35.335-50.645-93.244-81.6-157.251-81.6-79.639 
                0-149.848 47.913-178.866 122.063-6.442 16.458-25.006 24.577-41.462 18.137-16.458-6.441-24.578-25.004-18.137-41.461 18.559-47.423 50.547-87.906 92.506-117.073 
                42.977-29.875 93.448-45.666 145.959-45.666s102.982 15.791 145.959 45.665c17.076 11.87 32.501 25.616 46.041 40.94v-.203c0-17.673 14.327-32 32-32s32 14.326 
                32 31.999zm-35.672 221.4c-16.457-6.442-35.02 1.68-41.462 18.137-29.018 74.15-99.227 122.062-178.866 122.062-62.647 0-119.454-29.654-154.97-78.4h22.169c17.673 
                0 32-14.327 32-32s-14.327-32-32-32h-91.199c-17.673 0-32 14.327-32 32v91.2c0 17.673 14.327 32 32 32s32-14.327 32-32v-3.404c13.54 15.324 28.965 29.069 46.041 
                40.939 42.977 29.874 93.448 45.665 145.959 45.665s102.982-15.791 145.959-45.665c41.959-29.167 73.947-69.65 92.506-117.073 6.441-16.457-1.679-35.02-18.137-41.461z"
              />
            </svg>
          </button>
          <button onClick={() => removeActionPlan() } className="DeleteButton">
            <svg viewBox="0 0 1024 1024">
              <path d="m724.9 952.2h-423c-22.1 0-40.4-17.1-41.9-39.2l-36.3-539.6c-1.6-24.3 17.6-44.8 41.9-44.8h495.6c24.3 0 43.5 20.6 41.9 44.8l-36.3 539.6c-1.5 22.1-19.8 39.2-41.9 39.2zm119.6-702.3h-657c-.6 0-1-.4-1-1v-114.9c0-.6.4-1 1-1h657c.6 0 1 .4 1 1v114.8c0 .6-.4 1.1-1 1.1z"/>
              <path d="m690.9 189.5h-351.1c-.6 0-1-.4-1-1v-130.6c0-.6.4-1 1-1h351.1c.6 0 1 .4 1 1v130.6c0 .5-.4 1-1 1z"/>
            </svg>
          </button>
          <span className="ListItemName">{name}</span>
            {(showActionPlan) ?
              <>
                <svg className="DownCaretIcon" viewBox="0 0 292.362 292.362">
                  <path d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
                    C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
                    s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
                  />
                </svg>
              </>
            :
              <svg className="RightCaretIcon" viewBox="0 0 292.359 292.359">
                <path d="M222.979,133.331L95.073,5.424C91.456,1.807,87.178,0,82.226,0c-4.952,0-9.233,1.807-12.85,5.424
                  c-3.617,3.617-5.424,7.898-5.424,12.847v255.813c0,4.948,1.807,9.232,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428
                  c4.949,0,9.23-1.811,12.847-5.428l127.906-127.907c3.614-3.613,5.428-7.897,5.428-12.847
                  C228.407,141.229,226.594,136.948,222.979,133.331z"
                />
              </svg>
            }
        </div>
        {(syncingActionPlan) 
          ? 
            <div className="ActionPlanSyncingLoader">
              <div className="ActionPlanSyncingLoaderStatus">
                <MoonLoader
                  color="#897DBB"
                  loading={true}
                  cssOverride={{display: "block",margin: "0 auto"}}
                  size={50}
                  aria-label="Loading Spinner"
                />
                <p>Syncing Action Plan</p>
              </div>
              <div className="ActionPlanSyncingLoaderOverlay"></div>
            </div> 
          : 
            null
        }
        {(showPublicKeyPrompt) 
          ?
            <div className="PublicKeyPromptWrapper">
              <div className="PublicKeyPrompt">
                <span onClick={() => setShowPublicKeyPrompt(false)} className="GoBack">&larr; go back</span>
                <svg viewBox="0 0 97 97">
                  <path d="m9.30273 55.9749c0 5.8542 4.74577 10.6 10.59997 10.6h46.211c1.1046 0 2-.8954 2-2v-2.4417c0-.8149-.4996-1.5388-1.2293-1.9016-5.796-2.8816-9.7789-8.8602-9.7789-15.7747 0-9.7245 7.8832-17.6078 17.6077-17.6078 2.8886 0 5.6148.6956 8.0201 1.9284.8604.441 1.9596-.1467 1.9596-1.1135v-3.4268c0-5.8542-4.7458-10.6-10.6-10.6h-54.1902c-5.8542 0-10.59997 4.7458-10.59997 10.6zm15.29697-27.5443c1.0154-1.0154 2.6616-1.0154 3.677 0l9.8369 9.8369c1.0153 1.0153 1.0153 2.6616 0 3.6769l-9.8369 9.8369c-1.0154 1.0154-2.6616 1.0154-3.677 0-1.0154-1.0153-1.0154-2.6615 0-3.6769l7.9984-7.9984-7.9984-7.9985c-1.0154-1.0153-1.0154-2.6616 0-3.6769zm17.7269 18.9127c-1.436 0-2.6 1.164-2.6 2.6 0 1.4359 1.164 2.6 2.6 2.6h8.3022c1.436 0 2.6-1.1641 2.6-2.6 0-1.436-1.164-2.6-2.6-2.6z"/><path d="m61.1055 44.4569c0-7.5154 6.0924-13.6078 13.6077-13.6078 7.5154 0 13.6078 6.0924 13.6078 13.6078 0 6.626-4.7358 12.1459-11.0073 13.3596v16.4379h6.8978c1.436 0 2.6 1.1641 2.6 2.6s-1.164 2.6-2.6 2.6h-6.8978v6.8623c0 1.436-1.164 2.6-2.6 2.6-1.4359 0-2.6-1.164-2.6-2.6v-9.4302l-.0002-.0321.0002-.0321v-19.0057c-6.2719-1.2132-11.0082-6.7333-11.0082-13.3597zm13.6077-8.4078c-4.6434 0-8.4077 3.7643-8.4077 8.4078s3.7643 8.4078 8.4077 8.4078c4.6435 0 8.4078-3.7643 8.4078-8.4078s-3.7643-8.4078-8.4078-8.4078z"/>
                </svg>
                <textarea 
                  autoFocus 
                  onChange={(e) => setSSHKey(e.target.value)} 
                  value={sshKey} 
                  placeholder="Enter private key" 
                  type="textarea" 
                />
                <button 
                  className="PromptButton" 
                  onClick={() => {
                    setShowPublicKeyPrompt(false);
                    syncActionPlan();
                    setSSHKey("");
                  }}
                >
                  Sync Action Plan
                </button>
              </div>
              <div className="PublicKeyPromptOverlay"></div>
            </div>
          :
            null
        }
        {(showBasicAuthPrompt)
          ?
            <div className="BasicAuthPromptWrapper">
              <div className="BasicAuthPrompt">
                <span onClick={() => setShowBasicAuthPrompt(false)} className="GoBack">&larr; go back</span>
                <div className="PromptInput">
                  <svg viewBox="0 0 512 512">
                    <path d="M256,0c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,0,256,0z"/>
                    <path d="M423.966,358.195C387.006,320.667,338.009,300,286,300h-60c-52.008,0-101.006,20.667-137.966,58.195
                      C51.255,395.539,31,444.833,31,497c0,8.284,6.716,15,15,15h420c8.284,0,15-6.716,15-15
                      C481,444.833,460.745,395.539,423.966,358.195z"/>
                  </svg>
                  <input 
                    autoFocus 
                    onChange={(e) => setUsername(e.target.value)} 
                    value={username} 
                    placeholder="Username" 
                    type="text" 
                  />
                </div>
                <div className="PromptInput">
                  <svg viewBox="0 0 24 24">
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
                  </svg>
                  <input 
                    autoFocus 
                    onChange={(e) => setPassword(e.target.value)} 
                    value={password} 
                    placeholder="Password" 
                    type="password" 
                  />
                </div>
                <button 
                  className="PromptButton" 
                  onClick={() => {
                    setShowBasicAuthPrompt(false);
                    syncActionPlan();
                    setUsername("");
                    setPassword("");
                  }}
                >
                  Sync Action Plan
                </button>
              </div>
            </div>
          :
            null
        }
        {(showActionPlan) ? <ActionPlansActionList planName={name} exclusions={exclusions} setExclusions={setExclusions} actions={actions} /> : null}
      </div>
    )

    return listItem;
  }
  
  export default ActionPlansListItem