import { useEffect, useState } from "react";
import ResourcesLoadingOverlay from "./ResourcesLoadingOverlay";
import Tips from "./Tips";
import './StacksList.css';

function StacksListItem({name, currentValue, onInputChange, inputsDisabled}) {
  const [value, setValue] = useState(currentValue || "");
  const [showValue, setShowValue] = useState(false);

  const getType = () => (showValue) ? "text" : "password";

  const onChange = (e) => {
    setValue(e.target.value);
    onInputChange(name, e.target.value);
  }

  const setInputValue = () => (!inputsDisabled) ? value : "";

  return (
    <div className="StacksListItem">
      <label>{name}</label>
      {(inputsDisabled)
        ? 
          <svg className="LockIcon" viewBox="0 0 24 24">
            <path d="m16 9.5v-3.5a4 4 0 0 0 -8 0v3.5a2.5 2.5 0 0 0 -2.5 2.5v7a2.5 2.5 0 0 0 2.5 2.5h8a2.5 2.5 0 0 0 2.5-2.5v-7a2.5 2.5 0 0 0 -2.5-2.5zm-3 7.22v.28a1 1 0 0 1 -2 0v-.28a2 2 0 0 1 -1-1.72 2 2 0 0 1 4 0 2 2 0 0 1 -1 1.72zm1-7.22h-4v-3.5a2 2 0 0 1 4 0z"/>
          </svg>
        :
          <svg onClick={() => setShowValue(!showValue)} className="ShowItemValue" viewBox="0 0 488.85 488.85">
            <path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
              s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
              c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
              C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
              c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
          </svg>
      }
      <input 
        disabled={inputsDisabled}
        onChange={onChange}
        onFocus={(e) => e.target.select()}
        type={getType()}
        placeholder={(!inputsDisabled) ? name : "Unlock to edit"}
        value={setInputValue()}
      />
    </div>
  )
}

function StacksList({
  context, 
  overrides, 
  stackInputs, 
  actionPlans, 
  setInputChanged, 
  updateInput,
  loadingResources,
  inputsDisabled
}) {
  const [localInputs, setLocalInputs] = useState([]);

  const onInputChange = (name, value) => {
    updateInput(name, value);
    setInputChanged(true);
  }

  const getHeading = () => {
    if (overrides === "none") {
      return `${context.charAt(0).toUpperCase() + context.substr(1).toLowerCase()} Context  Inputs`;
    } else {
      return `${overrides.charAt(0).toUpperCase() + overrides.substr(1).toLowerCase()} Inputs`;
    }
  }

  useEffect(() => {
    const prepareInputs = () => {
      let inputs = [];
      if (overrides === "none") {
        stackInputs && Object.keys(stackInputs).forEach((name) => {
          if (name === "_overrides") return;
          inputs.push({name: name, value: (stackInputs[name] !== null) ? stackInputs[name] : ""})
        });
      }
      actionPlans.forEach((plan) => {
        if (overrides !== "none" && plan.name !== overrides) {
          return;
        }
        plan.fields && plan.fields.forEach((name) => {
          let exists = false;
          Object.values(inputs).forEach((input) => {
            if (name === input.name) {
              exists = true
            }
          })
          if (!exists) {
            let entry = {name: name}
            if (
              stackInputs && 
              stackInputs["_overrides"] && 
              stackInputs["_overrides"][overrides] && 
              stackInputs["_overrides"][overrides][name]
            ) {
              entry.value = stackInputs["_overrides"][overrides][name]
            }
            inputs.push(entry);
          }
        });
      });
      return inputs.sort((a, b) => a.name > b.name)
    }
    
    setLocalInputs(prepareInputs());
  }, [stackInputs, actionPlans, overrides, context]);

  return (
    <div className="StacksList Container">
      <h1 className="StacksListHeading">{getHeading()}</h1>
      <Tips tips={[
        "Enter your age secret key above to unlock and modify inputs.",
        "Required inputs must be provided before running an action plan.",
        "Inputs can be defined as action plan inputs or in the stack context."
      ]}/>
      {localInputs.map((input) => {
        return (
          <StacksListItem 
            key={`${context}-${overrides}-${input.name}-${input.value}`} 
            name={input.name} 
            currentValue={input.value} 
            onInputChange={onInputChange}
            inputsDisabled={inputsDisabled}
          />
        )
      })}
      {(!localInputs.length) ? <p className="NoRequiredInputs">No inputs are required for this action plan</p> : null}
      {(loadingResources) ? <ResourcesLoadingOverlay /> : null}
    </div>
  )
}

export default StacksList