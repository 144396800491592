import jwt_decode from "jwt-decode";
import { newSessionToken, refreshSessionToken } from "./api";

export function signIn(username, password, navigate) {
    newSessionToken(username, password, (token) => {
        localStorage.setItem("authtoken", token);
        navigate("/");
    });
}

export function signOut(navigate) {
    localStorage.removeItem("authtoken");
    navigate("/login");
}

export function authTokenValid(navigate) {
    let token = localStorage.getItem("authtoken");
    if (token === null) {
        if (navigate !== undefined) {
            navigate("/login");
        }
        return false;
    }
    return jwt_decode(token).exp > Math.floor(Date.now() / 1000);
}

export function refreshTokenInterval() {
    const interval = setInterval(() => {
        let token = localStorage.getItem("authtoken");
        refreshSessionToken(token, (data) => {
            localStorage.setItem("authtoken", data);
        })
    }, [1000 * 60 * 10]);
    return interval;
}

export function revokeToken() {
    localStorage.removeItem("authtoken");
}

export function getUser() {
    const token = localStorage.getItem("authtoken");
    console.log(jwt_decode(token));
    if (token) {
        return jwt_decode(token).sub;
    }
}

export function getTrialExpiration() {
    const token = localStorage.getItem("authtoken");
    if (token) {
        return jwt_decode(token).trialExpiration
    }
}

export function getToken() {
    return localStorage.getItem("authtoken");
}