import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, signOut } from "./utils/auth";
import logo from './logo-dark.svg';
import './Header.css';

function User() {
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const hideOptions = (e) => {
      if (String(e.target.className.baseVal).indexOf("UserToggleButton") !== -1) {
        return;
      }
      if (String(e.target.className).indexOf("NoUserOptionClose") === -1) {
        setShowOptions(false);
      }
    }
    window.addEventListener("mouseup", hideOptions);
    return () => window.removeEventListener("mouseup", hideOptions);
  }, [])

  return (
    <div className="User">
      <svg className="UserToggleButton" onClick={() => setShowOptions(!showOptions)} viewBox="0 0 45.532 45.532">
        <path className="UserToggleButton" d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
          S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
          c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
          c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
          c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
        />
      </svg>
      {(showOptions) 
        ?
          <div className="UserOptions NoUserOptionClose">
            <span className="UserOptionsCaret NoUserOptionClose"></span>
            <p className="NoUserOptionClose">{getUser()}</p>
            <button onClick={() => signOut(navigate)} className="SignOut NoUserOptionClose">Sign Out</button>
          </div>
        :
          null
      }
    </div>
  )
}

function Header() {
    const navigate = useNavigate();

    return (
      <div className="Header Container">
        <img onClick={() => navigate("/")} className="HeaderBrand" src={logo} alt="logo"/>
        <User />
      </div>
    );
  }
  
  export default Header;
  