import {useState} from 'react';
import "./ActionPlansActionsList.css";
import { setExcludedActions } from './utils/api';

function IncludedAction({action, removeAction}) {
	return (
		<button onClick={() => removeAction(action)} className="IncludeButton">
			<svg viewBox="0 0 512 512">
				<path d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z M383.28,340.858l-42.422,42.422
					L256,298.422l-84.858,84.858l-42.422-42.422L213.578,256l-84.858-84.858l42.422-42.422L256,213.578l84.858-84.858l42.422,42.422
					L298.422,256L383.28,340.858z"
				/>
			</svg>
		</button>
	)
}

function ExcludedAction({action, addAction}) {
	return (
		<button onClick={() => addAction(action)} className="ExcludeButton">
			<svg viewBox="0 0 490.05 490.05">
				<path d="M418.275,418.275c95.7-95.7,95.7-250.8,0-346.5s-250.8-95.7-346.5,0s-95.7,250.8,0,346.5S322.675,513.975,418.275,418.275
					z M157.175,207.575l55.1,55.1l120.7-120.6l42.7,42.7l-120.6,120.6l-42.8,42.7l-42.7-42.7l-55.1-55.1L157.175,207.575z"
				/>
			</svg>
		</button>
	)
}

function ActionPlansActionList({planName, actions, exclusions, setExclusions}) {
	const addAction = (action) => {
		let remove = new Set(exclusions);
		remove.add(action); 
		setExclusions(Array.from(remove));
		setExcludedActions(planName, Array.from(remove))
	}

	const removeAction = (action) => {
		let remove = new Set(exclusions);
		remove.delete(action); 
		setExclusions(Array.from(remove));
		setExcludedActions(planName, Array.from(remove))
	}

	return (
		<div className="ActionPlansActionsList">
			{(actions)
				? 
					actions.map((action, index) => (
						<ActionPlanActionsListItem 
							key={index}  
							action={action} 
							addAction={addAction}
							removeAction={removeAction}
							exclusions={exclusions}	
						/>
					))
				:
					<p className="NoActionsMatched">No actions are available for this action plan</p>
			}
		</div>
	)
}

function ActionPlanActionsListItem({action, addAction, removeAction, exclusions}) {
	const [showDescription, setShowDescription] = useState(false);

	return (
		<div 
			onClick={(e) => (e.target.className === "Action" || e.target.className === "ActionName") 
				? setShowDescription(!showDescription) 
				: null
			} 
			className="Action"
		>
			{(!new Set(exclusions).has(action.name)) 
				? <ExcludedAction key={action.name} action={action.name} addAction={addAction} /> 
				: <IncludedAction key={action.name} action={action.name} removeAction={removeAction} />
			}
			<span className="ActionName">{action.displayName}</span>
			{(showDescription) ? <p className="ActionDescription">{action.description}</p> : null}
		</div>
	)
}

export default ActionPlansActionList;